import axios from 'axios'

import { merge, localStorage } from '@/utils'

import { user } from '@/store/user'
import { view } from '@/store/view'
import { constants } from '@/store/constants'
import { store } from '../../index'
import { walletEvm } from '@/store/wallet-evm'

import { baseCheck } from '@/store/req/helper'
import { baseUrl } from '@/store/req/constants'

export type TAiAgentChatPostById = {
  aiAgentChatPostById: (id: number, history?: boolean) => Promise<any>
  aiAgentChatPostByIdBusy: boolean
}

export const aiAgentChatPostById: TAiAgentChatPostById = {
  async aiAgentChatPostById(id, history = false) {
    const { aiAgentChatPostByIdBusy }  = this
    const result = { data: {}, error: true }
    const { isLogged, info } = user
    const { tarot, upload } = view.emotion
    const { doctor } = view.aiAgent

    if (aiAgentChatPostByIdBusy || !walletEvm.signedIn) return result

    this.aiAgentChatPostByIdBusy = true

    const res = await axios({
      method: 'post',
      url: `${baseUrl}/ai/queryById`,
      data: {
        id
      },
      headers: {
        'Access-Token': info.session,
      }
    })

    // TEST
    // const res = {
    //   data: {
    //     code: '0',
    //     data: {
    //       status: 'finish',
    //       tarotCardId: 0,
    //       shared: false,
    //       aiSkillId: 1,
    //       tarotDirection: 1,
    //       id: 131,
    //       question: 'Q',
    //       aiAnswer: 'Donde antaño creció una Flor, la maldad se extiende.En lugar de la Doncella, una tumba hay presente.Y sobre su Tumba el Hombre permanece,pues su espada no atravesó a la Serpiente.',
    //       shareUrl: '',
    //       imageUrl: '',
    //       assets: []
    //     }
    //   }
    // }

    result.error = baseCheck(res)
    this.aiAgentChatPostByIdBusy = false

    // NOTE: 不论成功与否，要表现为占位
    const lastAIChat = {
      character: 'ai',
      chat: '',
      busy: false,
    }

    if (result.error) {
      // NOTE: 因为是定点历史查询，所以不可进行 reload

      return result
    }
// update
    const { data } = res.data
    const finished = data.status === 'finish'
    const tarotId = data.tarotCardId
    const dialogueId = data.id
    const ask = data.question
    const skillId = data.aiSkillId

    // NOTE: 不论如何，都会有一对一的形态。因为是历史，所以必须成功后才会创建 chat
    if (history) {
      doctor.addUserChat(ask)

      doctor.addAIChat()
    }

    // Cache
    const cache = localStorage.get(constants.storageKey.UNREAD_AI_CHAT) as Record<any, any> || {}
    // NOTE: 未完成的
    if (!finished) {
      cache[dialogueId] = { tarotId, dialogueId, skillId }
    } else {
      delete cache[dialogueId]
    }

    if (data.status === 'cancel') {
      store.message.error(`The issue with id ${id} has been canceled by the server.`)
      delete cache[dialogueId]
      localStorage.set(constants.storageKey.UNREAD_AI_CHAT, cache)

      merge(doctor, { lastAIChat })

      result.error = true
      return result
    }

    localStorage.set(constants.storageKey.UNREAD_AI_CHAT, cache)

    merge(lastAIChat, {
      dialogueId,
      tarotId,
      finished,
      busy: !finished,
      shared: data.shared,
      chat: finished && data.aiAnswer ? data.aiAnswer.replace(/\n/g, '<br />') : '',
      isUpright: data.tarotDirection === 1,
      ask,
      shareUrl: data.shareUrl,
      imageUrl: data.imageUrl,
      secondShareUrl: data.shareUrl
    })

    if (history) {
      doctor.editLastAIChat(lastAIChat)
    } else {
      doctor.editAIChatByDialogueId(id, lastAIChat)
    }

    result.data = {
      lastAIChat,
      postDisabled: !finished,
      postError: false
    }

    // sync
    merge(doctor, result.data)

    return result
  },
  aiAgentChatPostByIdBusy: false,
}