import {
  iSlidersH,
  iHome,
  iSend,
  iFlag,
  iTablet,
  iTelegram,
  iWatch,
  iGitbook
} from '../../components/icons'

import IAiWatchCoin1 from '@/assets/view/ai-watch/icon-1.svg'
import IAiWatchCoin2 from '@/assets/view/ai-watch/icon-2.svg'
import IEmoDAO from '@/assets/coin/emoDAO.png'
import ISleepDAO from '@/assets/coin/sleepDAO.png'
import IHighDAO from '@/assets/coin/highDAO.png'

type THeaderNavItem = { id?: string, prefix?: string, text: string, to?: string, content?: string, href?: string, component?: any, src?: any }
type THeader = {
  nav: Array<{ child?: Array<THeaderNavItem> } & THeaderNavItem>
  menu: {
    open: boolean
  }
}

const header: THeader = {
  nav: [
    { id: 'Home', text: 'Home', to: '/', component: iHome },
    // { id: 'Emotion', prefix: 'i-', text: 'Emotional Training', to: '/emotion', component: iSlidersH },
    // { id: 'Explore', text: 'Explore', to: '/explore', component: iTablet },
    { id: 'AIWatch', prefix: 'AIWatch', text: 'DeDate DePIN', to: '/ai-watch', component: iWatch, child: [
      { id: 'AIWatch', text: 'AI WATCH', to: '/ai-watch', component: IAiWatchCoin1, content: 'AI Watch enables real-time health data tracking' },
      { id: 'AIWatchBloodGlucoseMonitor', text: 'Blood Glucose Monitor', to: '/blood-glucose-monitor', component: IAiWatchCoin2, content: 'Automatically measures glucose, providing more comprehensive data' }
    ] },
    { id: 'ResearchGuilds', text: 'Research', to: '/research', component: iSlidersH },
    { id: 'GetInvolved', text: 'Involved', to: '/involved', component: iTablet },
    { id: 'AiAgent', text: 'AI Agent', to: '/ai-agent', component: iFlag },
    // { id: 'Launch', prefix: 'Launch', text: 'Launch', to: '/launch', component: iFlag, child: [
    //   { id: 'LaunchEMO', text: '$emo', to: '/launch/emo', src: IEmoDAO },
    //   { id: 'LaunchSLEEP', text: '$SLEEP', to: '/launch/sleep', src: ISleepDAO },
    //   { id: 'LaunchHIGH', text: '$high', to: '/launch/high', src: IHighDAO },
    // ] },
    { id: 'MiniApp', text: 'Mini App', href: 'https://t.me/junlalaaibot/miniapp', component: iTelegram },
    // { id: 'Earn', text: 'Earn', href: 'https://t.me/junlalaaibot/miniapp?startapp=nGa6yYMFh1XarUwUVwS', component: iSend },
    { id: 'Docs', text: 'Docs', href: 'https://docs.junlala.network/', component: iGitbook },
  ],
  menu: {
    open: false
  }
}

export default header