import common from './common'

import emotion from './emotion'
import accountReward from './accountReward'
import aiwatch from './aiwatch'
import contribute from './contribute'
import aiAgent from './aiAgent'

export const method = {
  common,

  emotion,
  accountReward,
  aiwatch,
  contribute,
  aiAgent
}