import axios from 'axios'

import { merge, localStorage, formatNumber } from '@/utils'

import { user } from '@/store/user'
import { view } from '@/store/view'
import { constants } from '@/store/constants'
import { store } from '../../index'
import { walletEvm } from '@/store/wallet-evm'

import { baseCheck } from '@/store/req/helper'
import { baseUrl } from '@/store/req/constants'


const skillTypes: Record<number, { welcome: string, referQuestions: Array<{ ask: string }>}> = {
  // 2: {
  //   welcome: 'Welcome to the AI Fate Teller! Wondering what the day holds for you? Ask me anything! <br/>e.g: Can I trade today?',
  //   referQuestions: [
  //     { ask: 'Can I trade today, or should I wait?' },
  //     { ask: 'How is my luck in love and relationships this week?' },
  //     { ask: 'Will today be a good day for making important decisions?' },
  //     { ask: 'Is this a good time to make a new start?' },
  //     { ask: 'Will I find new opportunities soon?' },
  //     { ask: 'How will my financial situation develop this month?' },
  //     { ask: 'Will I receive good news in the coming days?' },
  //     { ask: 'How is my general luck in life looking lately?' },
  //     { ask: 'Will I meet someone important soon in my personal life?' },
  //     { ask: 'Is now the right time to take action on my goals?' }
  //   ]
  // }
  99: {
    welcome: "Welcome. <br />I'm an ai virtual doctor. What's your problem, please?",
    referQuestions: [
      { ask: 'Recently, I’ve been feeling down. What should I do?' },
      { ask: 'I have a cold. Do you have any medication recommendations?' },
      { ask: 'Do you have any advice for heart health?' },
    ]
  }
}

export type TAiAgentChatHistoryBySkillId = {
  aiAgentChatHistoryBySkillId: () => Promise<any>
  aiAgentChatHistoryBySkillIdBusy: boolean
}

export const aiAgentChatHistoryBySkillId: TAiAgentChatHistoryBySkillId = {
  async aiAgentChatHistoryBySkillId() {
    const { aiAgentChatHistoryBySkillIdBusy }  = this
    const result = { data: {}, error: true }
    const { isLogged, info } = user
    const { tarot, upload } = view.emotion
    const { doctor } = view.aiAgent

    // if (aiAgentChatHistoryBySkillIdBusy || !walletEvm.signedIn) return result

    // this.aiAgentChatHistoryBySkillIdBusy = true

    // const res = await axios({
    //   method: 'post',
    //   url: `${baseUrl}/ai/userAskAIPage`,
    //   data: {
    //     pageSize: 10,
    //     pageNum: 1,
    //     status: 'going',
    //     aiSkillId: doctor.skillId
    //   },
    //   headers: {
    //     'Access-Token': info.session,
    //   }
    // })

    // result.error = baseCheck(res)
    // this.aiAgentChatHistoryBySkillIdBusy = false

    // if (result.error) return result

    // // update
    // const { data } = res.data

    // TEST:
    const data = {
      aiSkill: {
        name: 'doctor name',
        tags: ['ai'],
        quickQuestions: [],
        consume: 999,
        welcome: 'welcome'
      }
    }

    result.data = {
      skillName: data.aiSkill.name,
      skillTypes: data.aiSkill.tags,
      referQuestions: skillTypes[doctor.skillId] && skillTypes[doctor.skillId].referQuestions
        || data.aiSkill.quickQuestions.map(item => ({ ask: item })),
      skillCostView: formatNumber(data.aiSkill.consume),
      skillWelcome: skillTypes[doctor.skillId] && skillTypes[doctor.skillId].welcome
        || data.aiSkill.welcome
    }

    // sync
    merge(doctor, result.data)

    return result
  },
  aiAgentChatHistoryBySkillIdBusy: false,
}