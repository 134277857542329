import BN from 'bignumber.js'

import { view } from '../view'
import { user } from '../user'
import { req } from '../req'
import { wallet } from '../wallet'
import { walletEvm } from '../wallet-evm'
import { method } from '../method'
import { localStorage } from '@/utils'
import { constants } from '@/store/constants'
import router from '@/router'

export default {
}