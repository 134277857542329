import axios from 'axios'

import { merge, localStorage } from '@/utils'

import { user } from '@/store/user'
import { view } from '@/store/view'
import { constants } from '@/store/constants'
import { walletEvm } from '@/store/wallet-evm'

import { baseCheck } from '@/store/req/helper'
import { baseUrl } from '@/store/req/constants'

export type TAiAgentChatPostBySkillId = {
  aiAgentChatPostBySkillId: () => Promise<any>
  aiAgentChatPostBySkillIdBusy: boolean
}

export const aiAgentChatPostBySkillId: TAiAgentChatPostBySkillId = {
  async aiAgentChatPostBySkillId() {
    const { aiAgentChatPostBySkillIdBusy }  = this
    const result = { data: {}, error: true }
    const { isLogged, info } = user
    const { tarot, upload } = view.emotion
    const { doctor } = view.aiAgent

    if (aiAgentChatPostBySkillIdBusy || !walletEvm.signedIn) return result

    this.aiAgentChatPostBySkillIdBusy = true

    const res = await axios({
      method: 'post',
      url: `${baseUrl}/ai/ask`,
      data: {
        question: doctor.userLastPostMessage,
        aiSkillId: doctor.skillId
      },
      headers: {
        'Access-Token': info.session,
      }
    })

    // TEST
    // const res = {
    //   data: {
    //     code: '0',
    //     data: {
    //       id: 1,
    //       status: 'finish',
    //       tarotCardId: 0,
    //       shared: false,
    //       tarotDirection: 1,
    //       questionTitle: 'asdfasdf',
    //       shareUrl: '',
    //       assets: [
    //         { balance: 0 }
    //       ]
    //     }
    //   }
    // }

    result.error = baseCheck(res)
    this.aiAgentChatPostBySkillIdBusy = false

    // NOTE: 不论成功与否，要表现为占位
    const lastAIChat = {
      character: 'ai',
      chat: '',
      busy: false,
    }

    if (result.error) {
      // 解锁 post 提交
      merge(doctor, {
        postError: true,
        postDisabled: false
      })
      return result
    }

    // 创建空壳
    doctor.addAIChat()

    // update
    const { data } = res.data
    const finished = data.status === 'finish'
    const tarotId = data.tarotCardId
    const dialogueId = data.id

    // Cache
    const cache = localStorage.get(constants.storageKey.UNREAD_AI_CHAT) as Record<any, any> || {}
    // NOTE: 未完成的
    if (!finished) {
      cache[dialogueId] = { tarotId, dialogueId, skillId: doctor.skillId }
    } else {
      delete cache[dialogueId]
    }
    localStorage.set(constants.storageKey.UNREAD_AI_CHAT, cache)

    merge(lastAIChat, {
      dialogueId,
      tarotId,
      finished,
      shared: data.shared,
      busy: !finished,
      isUpright: data.tarotDirection === 1,
      ask: data.questionTitle,
      shareUrl: data.shareUrl,
      secondShareUrl: data.shareUrl
    })
    doctor.editLastAIChat(lastAIChat)

    // 补上 chat
    // lastAIChat.chat = data.questionExplain.replace(/\n/g, '<br />')
    // lastAIChat.chat = 'ok.\nThis is a list of some words and phrases that are used in changesets which are helpful to know so that contributors to changesets have a shared understanding of various concepts in the project.'.replace(/\n/g, '<br />')

    result.data = {
      lastAIChat,
      postError: false
    }

    // sync
    if (data.assets && data.assets.length) {
      merge(user.assets, {
        score: data.assets[0].balance
      })
    }
    merge(doctor, result.data)

    return result
  },
  aiAgentChatPostBySkillIdBusy: false,
}