import axios from 'axios'

import { merge, localStorage } from '@/utils'

import { user } from '../user'
import { wallet } from '../wallet'
import { walletEvm } from '../wallet-evm'
import { constants } from '@/store/constants'
import { method } from '@/store/method'
import { baseCheck } from './helper'
import { baseUrl } from './constants'

export type TLogInWallet = {
  logInWallet: () => Promise<any>
  logInWalletBusy: boolean
}

export const logInWallet = {
  async logInWallet() {
    const { logInWalletBusy }  = this as TLogInWallet
    const result = { data: {}, error: true }
    const signedMessage = method.common.getAccountLoginSignByCache()

    if (logInWalletBusy || !signedMessage) return result

    this.logInWalletBusy = true

    const res = await axios({
      method: 'post',
      url: `${baseUrl}/user/loginWithWallet`,
      data: {
        type: 'solana',
        evm: {
          // address: walletEvm.address.handled,
          // signStr: signedMessage,
          // signText: user.logInSignMessageText,
          address: '',
          signStr: '',
          signText: ''
        },
        solana: {
          address: wallet.address,
          signStr: signedMessage,
          signText: user.logInSignMessageText
        },
        invitationCode: user.param.invitCode,
        invitationWay: 1
      }
    })

    result.error = baseCheck(res)
    this.logInWalletBusy = false

    if (result.error) {
      method.common.baseLogOut()
      return
    }

    // update
    const { data } = res.data
    result.data = {
      createTs: data.regTime,
      session: data.token,
      id: data.userId
    }

    // sync
    merge(user.info, result.data)

    return result
  },
  logInWalletBusy: false,
}