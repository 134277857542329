import { merge } from '@/utils'

import { constants } from '@/store/constants'
import { user } from '@/store/user'

import IEmoDAO from '@/assets/coin/emoDAO.png'
import ISleepDAO from '@/assets/coin/sleepDAO.png'
import IHighDAO from '@/assets/coin/highDAO.png'
import { finished } from 'stream'

type TContribute = {
  payPortfolio: {
    remainingBusy: boolean
    idx: number
    open: boolean
    inputAmount: string
    busy: boolean
    finished: boolean

    reset: () => void
  }
  readonly payPortfolioItem: any
  portfolio: {
    list: Array<any>
    last: Array<any>
    _last: Array<any>
    pagination: {
      size: number
      current: number
      total: number
      isEnd: boolean
      isFirst: boolean
      isLast: boolean
      count: number
      prev: () => void
      next: () => void
    },
    reset: () => void
  }
  reset: () => void
}

const DEFAULTS_PAY_PORTFOLIO = {
  inputAmount: '1',
  busy: false,
  finished: false
}
const DEFAULTS = {
  payPortfolio: {
    remainingBusy: false,
    idx: 0,
    open: false,
    ...DEFAULTS_PAY_PORTFOLIO
  },
  portfolio: {
    list: [
      { eventIndex: 1, open: false, name: 'emoDAO', min: 1, max: 80000, symbol: 'emo', amount: '200000000', price: '0.0004', icon: IEmoDAO, chain: 'bsc', remaining: '0', address: constants.contract.emo, decimals: 18,
        href: 'https://pancakeswap.finance/?inputCurrency=0xE70d5CC08e70264140ac7Ae3a1BbcFd6217977F9&outputCurrency=BNB',
        content: `<span>The first initiative within JunLaLa Research Guilds focuses on the prevention and early intervention of depression.</span>
                  <span>Utilizing JunLaLa's comprehensive health and emotional data, this project aims to uncover new insights into early signs and preventative measures for depression.</span>`
      },
      { eventIndex: 2, open: false, name: 'sleepDAO', min: 1, max: 80000, symbol: 'SLEEP', amount: '200000000', price: '0.0004', icon: ISleepDAO, chain: 'sol', remaining: '0', address: constants.contract.sleep, decimals: 18,
        href: 'https://pump.fun/coin/GfunTQmVKKQPiSr93gvrLtecHswFVYhpnTR17R5Gpump',
        content: `<span>JunLaLa Research Guilds' second research project focuses on Insomnia. This initiative aims to evaluate sleep quality using objective data, monitor the effects of interventions, and study the relationship between insomnia and daytime activities.</span>
        <span>By leveraging quantitative measurements provided by the JunLaLa AIWatch and conducting thorough analyses, this research seeks to better understand the impact of insomnia on individuals and develop effective strategies to improve sleep health.</span>`
      },
      { eventIndex: 3, open: false, name: 'highDAO', min: 1, max: 80000, symbol: 'high', amount: '200000000', price: '0.0004', icon: IHighDAO, chain: 'sol', remaining: '0', address: constants.contract.high, decimals: 18,
        href: 'https://pump.fun/coin/2wimTWA6iCWyYhZYTTcBf2VPAJgRUjs6Y9ky4DNepump',
        content: `<span>$high is JunLaLa’s 3rd initiative aimed at combating the silent killer—hypertension. This innovative token serves as a cornerstone for promoting awareness, early detection, and proactive prevention of high blood pressure.</span>
        <span>Leveraging JunLaLa’s advanced AI-powered tools like the AIWatch and app, $high helps individuals understand their risk factors and take control of their cardiovascular health. Hypertension’s subtle symptoms—such as headaches, blurred vision, and fatigue—can lead to severe complications like heart failure, kidney damage, and arterial diseases if left unchecked.</span>`
      },
    ],
    _last: [],
    pagination: {
      size: 10,
      current: 1,
      total: 0,
      isEnd: false,
    }
  }
}

const contribute: TContribute = {
  payPortfolio: {
    ...DEFAULTS.payPortfolio,
    reset() {
      merge(this, DEFAULTS_PAY_PORTFOLIO)
    }
  },
  get payPortfolioItem () {
    const { payPortfolio, portfolio } = this

    return portfolio.list[payPortfolio.idx]
  },
  portfolio: {
    ...DEFAULTS.portfolio,

    // 最后请求的
    get last() {
      return this._last
    },
    set last(val) {
      const result = this._last = val

      // update
      this.list = this.list.concat(result)
    },

    pagination: {
      ...DEFAULTS.portfolio.pagination,

      get isFirst() {
        return this.current <= 1
      },
      get isLast() {
        return this.current >= this.count
      },
      get count() {
        const { total, size } = this

        return Math.ceil(total / size || 1)
      },
      prev() {
        !this.isFirst
          && this.current--
      },
      next() {
        !this.isLast
          && this.current++
      }
    },
    reset() {
      merge(this, DEFAULTS.portfolio)
    }
  },
  reset() {
    merge(this, DEFAULTS)
    this.portfolio.reset()
  }
}

export default contribute