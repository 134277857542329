import axios from 'axios'

import { merge, localStorage, formatNumber } from '@/utils'

import { user } from '@/store/user'
import { view } from '@/store/view'
import { constants } from '@/store/constants'
import { store } from '../../index'
import { walletEvm } from '@/store/wallet-evm'

import { baseCheck } from '@/store/req/helper'
import { baseUrl } from '@/store/req/constants'

export type TAiAgentChatPostHistoryById = {
  aiAgentChatPostHistoryById: (id: number) => Promise<any>
  aiAgentChatPostHistoryByIdBusy: boolean
}

export const aiAgentChatPostHistoryById: TAiAgentChatPostHistoryById = {
  async aiAgentChatPostHistoryById(id) {
    const { aiAgentChatPostHistoryByIdBusy }  = this
    const result = { data: {}, error: true }
    const { isLogged, info } = user
    const { tarot, upload } = view.emotion
    const { doctor } = view.aiAgent

    if (aiAgentChatPostHistoryByIdBusy || !walletEvm.signedIn) return result

    this.aiAgentChatPostHistoryByIdBusy = true

    const res = await axios({
      method: 'post',
      url: `${baseUrl}/userPredictionMachine/queryById`,
      data: {
        id
      },
      headers: {
        'Access-Token': info.session,
      }
    })

    result.error = baseCheck(res)
    this.aiAgentChatPostHistoryByIdBusy = false

    const lastAIChat = {
      character: 'ai',
      chat: '',
      busy: false,
    }

    if (result.error) {
      // NOTE: 因为是定点历史查询，所以不可进行 reload
      // 解锁 post 提交
      // merge(viewCracleChatStore, {
      //   postError: true,
      //   postDisabled: false
      // })
      return result
    }

    // update
    const { data } = res.data
    const finished = data.status === 'finish'
    const tarotId = data.tarotCardId
    const dialogueId = data.id

    // NOTE: 不论如何，都会有一对一的形态。因为是历史，所以必须成功后才会创建 chat
    doctor.addUserChat(data.questionTitle)

    doctor.addAIChat()

    // Cache
    const cache = localStorage.get(constants.storageKey.UNREAD_AI_CHAT) as Record<any, any> || {}
    // NOTE: 未完成的
    if (!finished) {
      // NOTE: 只有 tarot AI 才使用本接口
      cache[dialogueId] = { tarotId, dialogueId, skillId: 2 }
    } else {
      delete cache[dialogueId]
    }
    localStorage.set(constants.storageKey.UNREAD_AI_CHAT, cache)

    merge(lastAIChat, {
      dialogueId,
      tarotId,
      finished,
      busy: !finished,
      shared: data.shared,
      chat: finished ? data.questionExplain.replace(/\n/g, '<br />') : '',
      isUpright: data.tarotDirection === 1,
      ask: data.questionTitle,
      shareUrl: data.shareUrl,
      secondShareUrl: data.shareUrl
    })
    doctor.editLastAIChat(lastAIChat)

    result.data = {
      lastAIChat,
      postDisabled: !finished,
      postError: false
    }

    // sync
    merge(doctor, result.data)

    return result
  },
  aiAgentChatPostHistoryByIdBusy: false,
}