import { merge } from '@/utils'

import { constants } from '@/store/constants'
import { user } from '@/store/user'

export type TViewCracleChatReferQuestionsUnit = {
  ask: string
}

type TViewCracleChatUnit = {
  dialogueId?: number
  character: 'user' | 'ai'
  chat: string
  busy?: boolean
  finished?: boolean
  shareUrl?: string
  imageUrl?: string
  secondShareUrl?: string
  shared?: boolean

  // tarot
  tarotId?: number
  ask?: string
  isUpright?: boolean,

  // auxiliary
  referQuestions?: Array<TViewCracleChatReferQuestionsUnit>
}

type TAiAgent = {
  openDoctor: boolean
  doctor: {
    forceStop: boolean
    animateEnds: boolean
    hasHistroy: boolean

    skillId: number
    skillName: string
    skillTypes: Array<string>
    skillWelcome: string
    skillCostView: string
    referQuestions: Array<{ ask: string }>

    inputText: string
    maxLength: number
    userLastPostMessage: string // 用户输入后, 提交的最后一次信息
    readonly inputTextFormat: string // 与input的显示无关，得存在
    readonly postCheck: boolean
    postDisabled: boolean
    postError: boolean

    lastAIChat: null | TViewCracleChatUnit

    chats: Array<TViewCracleChatUnit>

    showDrawerShare: boolean
    extraInfo: boolean

    addUserChat: (chat?: string) => void
    addAIChat: (chat?: string, busy?: boolean) => void
    editLastAIChat: (chatUnit: any) => void
    editAIChatByDialogueId: (dialogueId: number, chatUnit: any) => void

    reset: () => void
  }
  reset: () => void
}

const DEFAULTS = {
  openDoctor: false,
  doctor: {
    forceStop:  true, // 强行中断打字机等计时器效果
    animateEnds: false,
    hasHistroy: false,

    skillId: 99,
    skillName: '',
    skillTypes: [],
    skillCostView: '',
    skillWelcome: '',
    referQuestions: [],

    inputText: '',
    maxLength: 200,
    userLastPostMessage: '',
    postDisabled: false,
    lastAIChat: null,
    postError: false,
    chats: [],

    showDrawerShare: false,
    extraInfo: false
  }
}

const aiAgent: TAiAgent = {
  ...DEFAULTS,

  doctor: {
    ...DEFAULTS.doctor,

  get inputTextFormat () {
    const { inputText } = this

    return String(inputText).trim()
  },
  get postCheck () {
    const { inputTextFormat } = this

    return !!inputTextFormat
  },

  addUserChat (chat = '') {
    const { inputTextFormat } = this
      const _chats = this.chats

    this.chats = [
      ..._chats,
      { character: 'user', chat: chat || inputTextFormat }
    ]
  },

  addAIChat (chat: string = '', busy: boolean = true) {
    const _chats = this.chats
    this.chats = [
      ..._chats,
      { character: 'ai', chat: '', busy, finished: false, referQuestions: [] }
    ]
  },

  editLastAIChat (chatUnit) {
    merge(this.chats[this.chats.length - 1], chatUnit)
  },

  // 按照 dialogueId 查询 chats 内对应的元素，并覆盖
  editAIChatByDialogueId (dialogueId, chatUnit) {
    let targetIdx = -1

    this.chats.some((item, idx) => {
      return item.dialogueId === dialogueId && (targetIdx = idx)
    })

    if (targetIdx > -1) {
      merge(this.chats[targetIdx], chatUnit)
    }
  },
    reset() {
      merge(this, DEFAULTS.doctor)
    }
  },
  reset() {
    merge(this, DEFAULTS)
  }
}

export default aiAgent